/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";

:root {
  --primary-color: #2a1559;
  --primary-color-darker: #0f062e;
  --primary-color-lighter: #6a5b8b;
  --white: #ffffff;
  --orange: #f28c0f;
  --blue: #38a3ef;
  --green: #06bf7b;
  --pink: #f23064;
}

@font-face {
  font-family: Poppins-Regular;
  src: url(assets/font/Poppins-Regular.ttf) format("opentype");
}

html,
body {
  /*background-image:url(https://lh4.googleusercontent.com/-XplyTa1Za-I/VMSgIyAYkHI/AAAAAAAADxM/oL-rD6VP4ts/w1184-h666/Android-Lollipop-wallpapers-Google-Now-Wallpaper-2.png);*/
  /*background-image: url('assets/fincatch/background.jpg');
  background: linear-gradient(#bfb9cd, #bfb9cd);*/
  /*background:linear-gradient(0deg, #002a1559, #002a1559), url('assets/fincatch/background.jpg');*/
  /* background:linear-gradient(0deg, rgba(42, 21, 89, 0.9), rgba(42, 21, 89, 0.9)), url('assets/fincatch/background.jpg');

  background-position:0px 50px;
  background-size:cover;
  background-repeat:repeat;
  min-height:100vh;
  font-family:'Poppins-Regular','Roboto', sans-serif;*/
  background-color: white;
  height: 100%;
}

/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }*/
