@use '@angular/material' as mat;
@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$md-fincatchpallet: (
    50 : #e5e3eb,
    100 : #bfb9cd,
    200 : #958aac,
    300 : #6a5b8b,
    400 : #4a3872,
    500 : #2a1559,
    600 : #251251,
    700 : #1f0f48,
    800 : #190c3e,
    900 : #0f062e,
    A100 : #8068ff,
    A200 : #5635ff,
    A400 : #2b02ff,
    A700 : #2500e7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-fincatchpalletpink: (
    50 : #fde6ec,
    100 : #fbc1d1,
    200 : #f998b2,
    300 : #f66e93,
    400 : #f44f7b,
    500 : #f23064,
    600 : #f02b5c,
    700 : #ee2452,
    800 : #ec1e48,
    900 : #e81336,
    A100 : #ffffff,
    A200 : #ffe4e7,
    A400 : #ffb1bb,
    A700 : #ff97a5,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-fincatchpalletblue: (
    50 : #e7f4fd,
    100 : #c3e3fa,
    200 : #9cd1f7,
    300 : #74bff4,
    400 : #56b1f1,
    500 : #38a3ef,
    600 : #329bed,
    700 : #2b91eb,
    800 : #2488e8,
    900 : #1777e4,
    A100 : #ffffff,
    A200 : #e2efff,
    A400 : #afd2ff,
    A700 : #96c3ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-fincatchpalletgreen: (
    50 : #e1f7ef,
    100 : #b4ecd7,
    200 : #83dfbd,
    300 : #51d2a3,
    400 : #2bc98f,
    500 : #06bf7b,
    600 : #05b973,
    700 : #04b168,
    800 : #03a95e,
    900 : #029b4b,
    A100 : #c6ffde,
    A200 : #93ffc0,
    A400 : #60ffa2,
    A700 : #47ff93,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-fincatchpalletorange: (
    50 : #fdf1e2,
    100 : #fbddb7,
    200 : #f9c687,
    300 : #f6af57,
    400 : #f49d33,
    500 : #f28c0f,
    600 : #f0840d,
    700 : #ee790b,
    800 : #ec6f08,
    900 : #e85c04,
    A100 : #ffffff,
    A200 : #ffe8dc,
    A400 : #ffc6a9,
    A700 : #ffb590,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$my-theme-primary: mat.define-palette($md-fincatchpallet, 700, 300, 900);
$my-theme-accent: mat.define-palette($md-fincatchpalletpink);
$my-theme-warn: mat.define-palette($md-fincatchpalletorange, 900);

/*$my-theme-primary: mat-palette($mat-indigo, 700, 300, 900);
$my-theme-accent: mat-palette($mat-light-blue);
$my-theme-warn: mat-palette($mat-deep-orange, A200);*/

/*$my-theme: mat.define-light-theme(
    $my-theme-primary,
    $my-theme-accent,
    $my-theme-warn
);

@include mat.all-legacy-component-themes($my-theme);*/

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-theme-primary,
      accent: $my-theme-accent,
      warn: $my-theme-warn,
    ),
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $my-theme-primary,
      accent: $my-theme-accent,
      warn: $my-theme-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component that you are using.
body.light,
body .light {
  // Adds density level 0 styles
  @include mat.all-component-colors($light-theme);
}
body.dark,
body .dark {
  // Adds density level 0 styles
  @include mat.all-component-colors($dark-theme);
}
